const { breakpoints } = require('./utils/tailwindSubsets');
const colors = require('tailwindcss/colors');

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}'
  ],
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    function ({ addVariant }) {
      addVariant('child', '& > *');
      addVariant('first-child', '& > :first-child');
    }
  ],
  variants: {
    extend: {
      backgroundColor: ['disabled']
    }
  },
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      white: colors.white,
      red: colors.red,
      blue: colors.blue,
      green: colors.emerald,
      yellow: colors.amber,
      gray: colors.zinc
    },
    screens: {
      ...breakpoints
    },
    fontFamily: {
      'hrg-display': ['Hurtigruten Display', 'Bebas Neue', 'Arial', 'sans'],
      jost: ['Jost', 'Arial', 'sans-serif']
    },
    fontSize: {
      '2xs': ['0.75rem', '1rem'],
      xs: ['0.8125rem', '1.5rem'],
      sm: ['0.875rem', '1.375rem'],
      base: ['1rem', '1.5rem'], // 16px
      lg: ['1.125rem', '1.5rem'],
      xl: ['1.25rem', '1.5rem'],
      '2xl': ['1.5rem', '2rem'],
      '3xl': ['1.625rem', '2rem'],
      '4xl': ['2rem', 1],
      '5xl': ['2.375rem', '2.25rem'],
      '6xl': ['2.75rem', '2.5rem'],
      '7xl': ['3.5rem', '2.5rem'],
      '8xl': ['4.5rem', '3.375rem'],
      none: [0, 0]
    },
    borderRadius: {
      none: '0',
      xs: '0.03125rem',
      sm: '0.0625rem',
      default: '0.125rem',
      lg: '0.1875rem',
      xl: '0.25rem',
      '2xl': '0.2875rem',
      '3xl': '0.3125rem',
      '4xl': '0.375rem',
      '5xl': '0.5rem',
      '6xl': '0.625rem',
      '7xl': '0.75rem',
      '8xl': '1rem',
      '9xl': '1.25rem',
      '10xl': '1.4375rem',
      '11xl': '3rem',
      '12xl': '3.125rem',
      '13xl': '3.75rem',
      '14xl': '6rem',
      full: '9999px'
    },
    flexGrow: {
      9999: 9999,
      1: 1
    },
    backgroundPosition: {
      offRight: '82% 50%',
      leftOffBottom: '0% 90%'
    },
    extend: {
      letterSpacing: {
        tightish: '-0.0625rem',
        wideish: '0.0625rem'
      },
      colors: {
        /* old colours - delete as you go */
        'background-gray': '#E5E5E5',
        'black-transparent-25': 'rgba(0, 0, 0, 0.25)',
        'black-transparent-65': 'rgba(0, 0, 0, 0.65)',
        'black-transparent-75': 'rgba(0, 0, 0, 0.75)',
        'snow-blue': '#D1F3FE',
        'frozen-blue': '#A0DBEF',
        'iceberg-blue': '#1FB1D1',
        'primary-hrg-red': '#D40000',
        'light-hrg-red': '#FDE3E1',
        'flush-red': '#E14D4D',
        'jungle-green': '#71AE3C',
        'light-green': '#9CC677',
        'lime-green': '#C9F1A6',
        'penguin-yellow': '#FFCF3B',
        'flare-yellow': '#FFF4D1',
        'light-black': '#4D4D4D',
        'medium-black': '#1D1D1D',
        'off-black': '#1A1A1A',
        'warm-gray-1': '#F8F8F8',
        'warm-gray-2': '#F1F1F1',
        'warm-gray-2-transparent': 'rgba(229, 227, 227, 0.5)',
        'warm-gray-3': '#E5E3E3',
        'warm-gray-4': '#D1CECC',
        'warm-gray-5': '#B3B3B3',
        'light-green-secondary': '#DFFFC2',
        'dark-green-secondary': '#235412',
        beige: '#EBE2DC',
        'primary-red': {
          50: '#fbf6f6',
          100: '#f6cccc',
          200: '#ee9999',
          300: '#dd3333',
          400: '#e56666',
          500: '#d40000',
          600: '#c50000',
          700: '#af07047',
          800: '#9e0707',
          900: '#7b0606'
        },
        /* new HX colors */
        'primary-hx-indigo': '#374D67',
        'hx-caviar': '#132730',
        'hx-gray': '#8994A1',
        'hx-gray-2': '#666666',
        'hx-gray-3': '#383838',
        'hx-gray-4': '#494949',
        'hx-light-gray': '#EFEFEF',
        'hx-light-gray-2': '#CCCCCC',
        'hx-light-gray-3': '#858585',
        'hx-sand': '#C8BBA2',
        'hx-pearl': '#DED7CA',
        'hx-mistyindigo': '#D8DBE0',
        'hx-dark-card-hover': '#28353C',
        'hx-green-confirmation': '#CEE8B7',
        'indigo-light': {
          100: '#ebedf0',
          200: '#d7dbe1',
          300: '#c3cad1',
          400: '#afb8c2',
          500: '#9ba6b3',
          600: '#8794a4',
          700: '#738295',
          800: '#5f7185',
          900: '#4b5f76'
        },
        'hx-red': '#8D1D29',
        'hx-blue-confirm': '#BFDDE7',
        'hx-neutrals-10': '#F2F2F2',
        'hx-neutrals-60': '#7D7F7C',
        'hx-neutrals-70': '#646663',
        'hx-secondary-red': {
          100: '#F4E8EA'
        }
      },
      boxShadow: {
        'button-primary': 'inset 0 0 0 2px #D40000',
        'button-primary-disabled': 'inset 0 0 0 2px #FDE3E1',
        'button-secondary': 'inset 0 0 0 2px #000000',
        'button-secondary-disabled': 'inset 0 0 0 2px #E5E3E3',
        'ship-info': '0 10px 30px rgba(0, 0, 0, 0.5)',
        'element-hover': '0px 2px 4px 0px rgba(0,0,0,0.25)',
        'flat-hover': '0px 2px 2px 0px rgba(0,0,0,0.1)',
        'fab-hover': '0px 8px 12px 0px rgba(0,0,0,0.25)',
        'container-top': '0px -4px 12px rgba(0, 0, 0, 0.25)',
        'hx-card': '0px 2px 4px 0px rgba(0, 0, 0, 0.10)'
      },
      dropShadow: {
        'sticky-footer': '0px 4px 4px rgba(0, 0, 0, 0.25)',
        'card-shadow': '0px 2px 2px rgba(0, 0, 0, 0.15)',
        'card-hover': '0px 4px 4px rgba(0, 0, 0, 0.25)'
      },
      gridTemplateColumns: {
        destination: 'repeat(auto-fit, minmax(9.8rem, 1fr))',
        'voyages-sm': 'repeat(auto-fit, minmax(272px, 1fr))',
        'voyage-detail-departures-lg': 'repeat(auto-fit, 140px)',
        sailingdates: 'repeat(auto-fit, 250px)',
        'brochure-cards': 'repeat(auto-fit, 325px)',
        '1-max-content': 'repeat(1, minmax(0, max-content))',
        '2-max-content': 'repeat(2, minmax(0, max-content))',
        '7-minmax-content': 'repeat(7, minmax(max-content, 1fr))'
      },
      minHeight: {
        xs: '20rem',
        'screen-minus-header': 'calc(100vh - 9rem)'
      },
      minWidth: {
        button: '96px',
        half: '50%'
      },
      maxHeight: {
        button: '56px',
        'screen-minus-header': 'calc(100vh - 9rem)'
      },
      maxWidth: {
        fluid: '1228px',
        header: '1440px'
      },
      zIndex: {
        '-1': '-1',
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100'
      },
      padding: {
        'p-52': '52%',
        'p-42': '42%',
        'p-32': '32%',
        'p-20': '20%',
        'p-15': '15%',
        'p-10': '10%',
        'p-5': '5%',
        'summary-widget': '432px',
        'hx-section': '3.5rem'
      },
      outline: {
        yellow: '4px solid #FFCF3B'
      },
      transitionTimingFunction: {
        'ease-in-out-smooth': 'cubic-bezier(0.65, 0, 0.35, 1)'
      },
      transitionProperty: {
        bgsize: 'background-size'
      },
      animation: {
        fadeInPop: 'fadeIn 0.3s ease-in-out, pop 0.3s ease-in-out',
        fadeInDelayed: 'fadeIn 0.3s ease-in-out 0.3s forwards',
        fadeIn: 'fadeIn 0.6s ease-in-out 200ms forwards'
      },
      keyframes: () => ({
        fadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 }
        },
        pop: {
          '0%': { transform: 'scale(0.75)' },
          '75%': { transform: 'scale(1.05)' },
          '100%': { transform: 'scale(1)' }
        }
      })
    }
  }
};
